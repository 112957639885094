/* You can add global styles to this file, and also import other style files */
@import "@simpl/element-theme/src/theme";
@import "@simpl/element-ng/simpl-element-ng";

// Import the SiMPL Scheduler SCSS styles
@import "@simpl/scheduler-ng/simpl-scheduler-ng";

// OpenLayers styling for SiMPL Maps
@import 'ol/ol.css';
@import 'ol-ext/dist/ol-ext.css';

$gms-title-bar-height: 36px;
$gms-title-bar-bg: $element-base-1;

.gms-has-navbar-titlebar-fixed-top {
  padding-top:50px + $gms-title-bar-height;
}

.gms-has-navbar-titlebar-fixed-top-navbar header {
  top: $gms-title-bar-height !important;
}

.gms-has-navbar-titlebar-fixed-top-lp nav {
  top:100px + $gms-title-bar-height !important;
}

.gms-has-navbar-titlebar-fixed-top-rp > div:first-child {
  top: 100px + $gms-title-bar-height;
}

.gms-has-navbar-titlebar-fixed-top-login si-landing-page {
  padding-top: $gms-title-bar-height;
}

.gms-no-selectable-text {
  user-select: none;
}
